<template>
  <div class="container">
  <n-page-header subtitle="回退" @back="comeBackCompany" style="color: aquamarine">
  </n-page-header>

  <div class="link-container">
    <div class="link-info">
      <h2>原链接：<a :href="info.Original" target="_blank">{{ info.Original }}</a></h2>
      <h2>短链接：<a :href="shortLink" target="_blank">{{ shortLink }}</a></h2>
    </div>
<!--    <div class="qr-code">-->
<!--      <n-qr-code :value="shortLink"/>-->
<!--    </div>-->
  </div>

  <n-statistic
      label="你的链接共被点击了"
      tabular-nums
      display="flex"
      style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <n-number-animation
        show-separator
        :from="0"
        :to="clicksCount"
        :active="true">
    </n-number-animation>
    <template #suffix>
      次
    </template>
  </n-statistic>

  <div class="select-container">
    <n-select
        v-model:value="statisticLevel"
        :options="options"
        style="max-width: 10em"
        @update:value="ChangeTimeStatisLevel"/>
  </div>

  <n-table :bordered="false" :single-line="false" style="background:  rgba(173, 216, 230, 0.3); /* 淡蓝色带透明 */">
    <thead>
    <tr>
      <th>时间段</th>
      <th>IP</th>
      <th>点击次数</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in clickList" :key="index">
      <td>{{ item.Time }}</td>
      <td>{{ item.IP }}</td>
      <td>{{ item.count || 1 }}</td>
    </tr>
    </tbody>
  </n-table>
  </div>
</template>

<script setup>
import axiosService from '@/axiosutils';
import { ref, watch} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { format, parse } from 'date-fns';

const routes = useRoute();
const router = useRouter();

let comeBackCompany = () => {
  router.back();
};

const code = routes.params.code;

const clicksCount = ref(0);
const info = ref({});
const clickList = ref([]);
const clickListTemp = ref([]);

const shortLink = ref('');

const options = ref([
  { label: '请选择统计级别', value: 0, disabled: true },
  { label: '详细', value: 1 },
  { label: '按分钟汇总', value: 2 },
  { label: '按小时汇总', value: 3 },
  { label: '按天汇总', value: 4 },
  { label: '按月汇总', value: 5 }
]);

const statisticLevel = ref(1);

const fetchData = async (code) => {
  const response = await axiosService.get(`/clicks/getClicks/${code}`);
  const resData = response.data.data;
  clickList.value = resData.clicks;
  clickListTemp.value = resData.clicks;
  clicksCount.value = resData.clicks.length;
  info.value = resData.info;
  updateShortLink(info);
  ChangeTimeStatisLevel(statisticLevel.value);
};

fetchData(code);

const ChangeTimeStatisLevel = (value) => {
  clickList.value = mergeClicks(value, clickListTemp.value);
};

const updateShortLink = (info) => {
  shortLink.value = `${process.env.VUE_APP_API_URL}/short/${info.value.Code}`;
};

watch(() => code, (newShortId) => {
  fetchData(newShortId);
});

const mergeClicks = (level, clickList) => {
  const getTimeSegment = (timestamp, level) => {
    let date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      date = parse(timestamp, 'yyyy-MM-dd HH:mm:ss', new Date());
    }
    switch (level) {
      case 1: return format(date, 'yyyy-MM-dd HH:mm:ss');
      case 2: return format(date, 'yyyy-MM-dd HH:mm');
      case 3: return format(date, 'yyyy-MM-dd HH');
      case 4: return format(date, 'yyyy-MM-dd');
      case 5: return format(date, 'yyyy-MM');
      case 6: return format(date, 'yyyy');
      default: throw new Error(`Unsupported level: ${level}`);
    }
  };

  const mergedClicks = clickList.reduce((acc, current) => {
    const { OriginTime, IP, count } = current;
    const timeSegment = getTimeSegment(OriginTime || current.Time, level);
    if (!acc[timeSegment]) {
      acc[timeSegment] = { Time: timeSegment, IP, count: count || 1 };
    } else {
      acc[timeSegment].count += count || 1;
    }
    return acc;
  }, {});

  return Object.values(mergedClicks);
}
</script>

<style scoped>
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  margin-bottom: 20px;
}

.link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.link-info h2 {
  margin: 5px 0;
}

.qr-code {
  margin-left: 20px;
}

.select-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

n-table {
  width: 100%;
}

html, body {
  height: 100%;
  margin: 0;
}
.container {
  height: 100vh;
  background-color: rgba(173, 216, 230, 0.3); /* 淡蓝色带透明 */
}
</style>
