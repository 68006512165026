import { createApp, h } from 'vue';
import App from './App.vue'
// import router from '@/router11';
import router11 from '@/router';
import naive from 'naive-ui'

const app  = createApp({
  render: ()=>h(App)
});





app.use(naive)
app.use(router11)
app.mount('#app')