import axios from 'axios'


// 创建一个axios实例
const axiosService = axios.create({
  //if production else VUE_APP_BASE_API+/api
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL, // url = base url + request url

  timeout: 5000 // 请求超时时间
})

// 请求拦截器
axiosService.interceptors.request.use(
  config => {
    console.log('config', config)
    // 在发送请求之前做些什么
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
export default axiosService
