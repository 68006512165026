<template>

  <div class="container">
    <div class="input-container">
      <input class="input" v-model="originUrl" placeholder="输入要缩短的链接" />
      <button class="generate-button" @click="generateShortLink" :disabled="loading">
        生成短链接
        <span v-if="loading" class="loading-bar" :class="{'fade-out': !loading}"></span>
      </button>
    </div>

    <n-list class="list">
      <n-list-item v-for="(shortUrl, index) in shortUrls" :key="index" class="list-item">
        <div class="url-and-buttons">
          <div class="url-container">{{ shortUrl.fullUrl }}</div>
          <n-button quaternary type="success" @click="copyToClipboard(shortUrl.fullUrl)">
            复制
          </n-button>
          <n-button quaternary type="error" @click="deleteShortLink(shortUrl.code)">
            删除
          </n-button>
          <n-button quaternary type="info" @click="goToAbout(shortUrl)">
            点击统计
          </n-button>
        </div>
      </n-list-item>
    </n-list>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { NButton, NList, NListItem } from 'naive-ui';
import { useMessage } from 'naive-ui'
import axiosService from '@/axiosutils';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useDialog } from 'naive-ui'
const dialog = useDialog();

const message = useMessage()
const router = useRouter();
const route = useRoute();

const ownerCode = ref('');
let ownerCodeQuery= route.query?.ownerCode
console.log('ownerCode',ownerCode)





const originUrl = ref('');
const loading = ref(false);
const shortUrls = ref([]);
// shortUrls.value.push({ fullUrl: 'https://www.yiche.com/baike/51722.htm', code: 'cvrnvNkV7P7r',id:'2' }); // 示例数据


onMounted( async () => {
  console.log('before get short link')
  console.log('shortUrls',shortUrls.value)

    if (ownerCodeQuery) {
      ownerCode.value= ownerCodeQuery
      // getShortLinks(ownerCodeQuery)
    }

})
async function getShortLinks (ownerCodeQuery) {
  // short/ownerLink/nXS0Io4K
  const res = await axiosService.get(`/short/ownerLink/${ownerCodeQuery}`)
  const links = res.data.info
  console.log(links)
  shortUrls.value = links.map(item => {
    return {
      fullUrl: `${process.env.VUE_APP_API_URL}/short/${item.Code}`,
      code: item.Code,
      id: item.ID
    }
  })
  console.log(shortUrls.value)
}



function isValidURL(url) {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.[a-z.]{2,6}[/\w .-]*/;
  return regex.test(url);
}

const generateShortLink = async () => {

  //输入的链接必须要是正确的url,http或者https都可以

  if (!originUrl.value||!isValidURL(originUrl.value)) {
    message.error('请输入一个正确的链接')
    return;
  }
  //不能是当前域名
  if (originUrl.value.includes(process.env.VUE_APP_API_URL)) {
    message.error('不能是当前域名')
    return
  }


  try {
    loading.value = true;
    let formdata = new FormData();
    formdata.append("url", originUrl.value);
    console.log('ownerCode',ownerCode.value)
    if(ownerCode.value){
      formdata.append("ownerCode", ownerCode.value);
    }
    console.log(formdata)
    const res = await axiosService.post(`/short`,
        {
          url:originUrl.value,
          ownerCode:ownerCode.value
        }
      ,
    {
          headers:{
            'Content-Type': 'multipart/form-data'
          },
          responseType:'json'
      },
    );
    const data=res.data.link
    console.log(data);
    if (data.Code) {
      const fullUrl = `${process.env.VUE_APP_API_URL}/short/${data.Code}`;
      shortUrls.value.push({ fullUrl: fullUrl, code: data.Code });
      originUrl.value = ''; // 清空输入框
    }
    if(res.data.ownerCode){

      const ownerCredentialUrl = `${window.location.origin}#${route.fullPath}?ownerCode=${res.data.ownerCode}`;
      console.log('ownerCredentialUrl',ownerCredentialUrl)
      //修改当前路径，添加ownerCode参数   然后刷新
      // originUrl.value = ''; // 清空输入框
      router.push({
        path: '/shortlink',
        query: {
          ownerCode: res.data.ownerCode,
        }
      })

      dialog.warning({
        title: '提示',
        content: `你可以使用以下链接查看你的所有的短链接，注意此链接不会再次生成，请谨慎保存${ownerCredentialUrl}`,
        positiveText: '确定并复制链接',
        negativeText: '关闭',
        onPositiveClick: () => {
          //主地址+ownerCode
          copyToClipboard(`${ownerCredentialUrl}`)
          // copyToClipboard(`/shortlink?ownerCode=${res.data.ownerCode}`)
        },
        onNegativeClick: () => {
          // message.error('不确定')

        }
      })
    }
  } catch (error) {
    console.error('生成短链接失败:', error);
    message.error('生成短链接失败')
  } finally {
    setTimeout(() => { loading.value = false }, 500); // 延迟取消加载状态以实现平滑过渡
  }
};

const copyToClipboard = (shortUrl) => {
  message.success('复制成功')
  navigator.clipboard.writeText(shortUrl).then(() => {
  //提示框 0.5秒消失

  });
};

const deleteShortLink = async (code) => {
  try {
    await axiosService.delete(`/short/${code}`);
    shortUrls.value = shortUrls.value.filter(url => url.code !== code);
    message.success('删除成功')
  } catch (error) {
    console.error('删除短链接失败:', error);
  }
}



const goToAbout=(link)=> {
  console.log("link",link)
  router.push({path:`/clickPage/${link.code}`,params:{ShortLink:link}})
}

//watch ownerCode
watch(ownerCode, (newVal) => {

    getShortLinks(newVal);

})

</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* 固定在页面顶部的一部分 */
  height: 100vh;
  background-color: rgba(173, 216, 230, 0.3); /* 淡蓝色带透明 */
  padding: 20px;
  padding-top: 150px; /* 为了更好地展示输入框 */
  box-sizing: border-box; /* 包括内边距和边框的总高度 */
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
  border: 2px solid #87ceeb; /* 淡蓝色边框 */
  padding: 10px;
  border-radius: 5px;
  background-color: white; /* 白色背景 */
}

.input {
  flex: 1;
  padding: 10px;
  border: none; /* 隐藏输入框边框 */
  outline: none;
}

.generate-button {
  margin-left: 10px;
  background-color: #1e90ff; /* 蓝色背景 */
  color: white;
  border: none; /* 去掉按钮边框 */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.generate-button:disabled {
  cursor: not-allowed;
  background-color: #1e90ff; /* 确保禁用时背景色保持不变 */
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  animation: loading 5s linear infinite;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

@keyframes loading {
  0% {
    left: -100%;
    width: 100%;
  }
  50% {
    left: 50%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.list {
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  max-height: calc(100vh - 250px); /* 设置最大高度，超出时出现滚动条 */
}

.list-item {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: transparent; /* 背景颜色与页面颜色一致 */
}

.url-and-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}



.url-container {
  flex: 1;
  padding: 5px;
  background-color: rgba(173, 216, 230, 0.3); /* 灰色背景与页面颜色一致 */
  border-radius: 5px;
  font-family: monospace; /* 等宽字体 */
  color: black; /* 设置文字颜色为黑色 */
  margin-right: 10px; /* 确保按钮和URL之间有间距 */
}

</style>
