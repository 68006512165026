import {
  createRouter,
  createWebHashHistory,
} from 'vue-router';
// import ShortLinkGenerator from '@/components/ShortLinkGenerator.vue';
import ClicksPag from '@/components/ClicksPag.vue';
import ShortLinkTmep from '@/components/ShortLinkTmep.vue';

const routes = [
  { path: '/', redirect: '/shortlink' },
  {
    path: '/shortlink', component: ShortLinkTmep, name: 'shortlink',
  },
  {
    path: '/clickPage/:code', component: ClicksPag,
    props: true,
    
  },
];

const router11 = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_API_URL),
  routes,
});

export default router11;